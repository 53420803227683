.page-tile
	background: #f4f4f4
	position: relative
	margin-left: -21px
	margin-right: -25px
	&--modal
		height: auto
		margin-top: -35px
		width: fit-content
		padding-right: 20px
		padding-top: 10px
		padding-bottom: 10px
		overflow: auto
		margin-left: -40%
	&--new
		margin-top: -48px
	&--new__
		margin-top: -51px
		margin-left: -25px
	&--new_
		height: 90vh
		z-index: 10
		margin-top: -67px
		.page-tile__body
			height: auto
	&--add, &--add_
		margin-left: 0
		margin-right: 0

.page-tile_group
	display: block
	&--i
		.page-tile__body
			width: 380px !important
			min-width: 380px !important
			max-width: 380px !important
			.page-tile__group
				input:read-only
					background: transparent !important
				.invoice-email
					margin-left: 20px
					margin-top: 3px
					height: 20px
				.fa-pause
					color: rgb(199, 199, 199)
					margin: 7px
					margin-left: 15px
	&--i-amount
		margin: 20px
		margin-top: 10px
		.page-tile__body
			margin: 0
			width: 203px !important
			min-width: 203px !important
			max-width: 203px !important
			.page-tile__title
				justify-content: center
			.page-tile__group
				margin: 0
				.page-tile__group-label
					font-size: 24px
					line-height: inherit
					width: auto
					margin: 0 auto

		.page-tile__symbol
			margin: 0 20px
			font-size: 55px
			background-color: #dcdcdc
			-webkit-background-clip: text
			-moz-background-clip: text
			background-clip: text
			color: transparent
			text-shadow: rgba(255, 255, 255, 0.5) 0 2px 2px

.page-tile__panel
	background: white
	margin: 20px
	position: relative
	top: 10px
	margin-right: 0
	margin-top: 0
	display: flex
	.page-tile__panel-label
		display: inline-block
		.btn
			padding: 6.5px 10px

	.btn
		border: none
		color: $secondary-color
		border-radius: 0
		.fa
			font-size: 18px
		.fa-spinner
			padding: 0 .2px
			line-height: 1.3
			margin-left: 0
			font-size: 15px

.page-tile__body
	height: 100%
	overflow-x: hidden
	transition: 0.2s
	width: 500px
	min-width: 500px
	max-width: 600px
	background: white
	padding: 15px
	margin: 0 0 10px 20px
	.period-picker__dropdown
		.period-picker__group-header
			text-align: center
		.period-picker__group
			top: -15em
		.period-picker__group-2em
			top: 2em
		.btn
			border: none
			transition: .2s
			&:hover
				color: $secondary-color
	.ng-select-disabled, .ng-select-disabled-tr
		background-color: transparent
		.ng-select
			background-color: #f5f5f5
			.ng-select-container
				border-bottom-color: transparent !important
	input[readonly]
		border-bottom-color: transparent
	&--200
		min-width: 200px !important
		width: 200px !important
	&--240
		min-width: 240px !important
		width: 240px !important
	&--500
		width: 500px !important
	&--555
		width: 555px !important
	&--600
		width: 600px !important
	&--670
		max-width: 670px
		width: 700px !important
		.page-tile__group
			textarea
				height: 45px !important
			.page-tile__group-input
				width: 600px
	&--700
		position: relative
		max-width: 700px
		width: 700px !important
	&--900
		position: relative
		max-width: 900px
		width: 900px !important
	fieldset[disabled]
		.ng-select
			pointer-events: none
			background-color: #f5f5f5
			.ng-select-container
				border-bottom-color: transparent !important
		input
			border-bottom-color: transparent !important
		.btn
			opacity: 1
			background-color: #f5f5f5 !important

	&--hidden-none
		overflow-x: unset
	&--400, &--500
		min-width: 300px !important
		width: 400px !important
		.page-tile__group
			justify-content: flex-start
			& > :nth-child(2)
				width: 70%
		.page-tile__group--block
			& > :nth-child(2)
				width: 100% !important
			.ng-select-container
				width: 100% !important
				height: auto !important
		.checkbox
			margin-right: 5px
	&--500
		width: 500px !important
	&--530
		width: 530px !important		
		.ng-select .ng-select-container
			width: 350px !important

.page-tile__body, .page-tile__panel, .page-tile__grid
	box-shadow: 0 7px 7px -6px rgba(0, 0, 0, 0.14)

.page-tile__title
	width: 100%
	padding: 0 0 5px 0
	font-size: 1.2em
	display: flex
	justify-content: space-between
	&--column
		flex-direction: column
	.page-tile__subtitle
		font-size: 14px
		opacity: 0.7
		color: #6d6e7a
		line-height: 2
		&--warning
			line-height: 1.8
			color: $color-warning
		&--error
			line-height: 1.8
			color: $color-error
		&--success
			line-height: 1.8
			color: $color-success

.page-tile__info
	display: flex
	align-items: center
	border-radius: 5px
	padding: 10px
	background: ivory
	border: 1px solid #F6C342
	font-size: 13px
	span
		font-weight: 600
	.fa
		margin-right: 10px
		font-size: 25px
		color: $color-warning

.page-tile__flex
	display: flex
	height: 28.67px
	&--fe
		justify-content: flex-start
		margin-top: -5px
		padding-bottom: 5px
		.page-tile__group-label
			width: 150px !important
		.ng-select .ng-select-container
			width: 320px !important
		.btn
			margin-top: 5px
			padding: 8px 9px
			height: 28px

.page-tile__group
	margin: 5px 0
	width: 100%
	display: flex
	position: relative
	justify-content: space-between
	.ng-select
		.ng-option
			color: #666 !important
		&.ng-invalid
			.ng-select-container
				border-bottom-color: indianred !important
		&.ng-invalid:focus
			.ng-select-container
				outline: none !important
				-webkit-box-shadow: none !important
				box-shadow: none !important
		.ng-select-container
			background: transparent !important
			border: none !important
			border-bottom: 1px solid #b0bec5 !important
			border-radius: 0 !important
			width: 350px
			min-height: 28px
			height: 28px
			&.ng-invalid
				border-bottom-color: indianred !important
			.ng-value
				color: #666
				font: 1em/1.6 "SF-medium"
			.ng-value, .ng-input > input
				padding-left: 0 !important
	.ng-select.ng-select-opened.ng-select-bottom > .ng-select-container,
	.ng-select.ng-select-opened.ng-select-top > .ng-select-container
		border-bottom-color: $secondary-color !important
	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button
		-webkit-appearance: none
		margin-right: 5px
	input[type="number"]
		-moz-appearance: textfield
	textarea
		resize: none
		height: 70px
	&--select-220
		.ng-dropdown-panel .ng-dropdown-panel-items
			max-height: 220px !important
	&--select-200
		.ng-dropdown-panel .ng-dropdown-panel-items
			max-height: 200px !important
	&--select-180
		.ng-dropdown-panel .ng-dropdown-panel-items
			max-height: 180px !important
	&--select-150
		.ng-dropdown-panel .ng-dropdown-panel-items
			max-height: 150px !important
			width: 350px
		.ng-dropdown-panel
			position: unset !important
			border: none !important
	&--select-140
		.ng-dropdown-panel .ng-dropdown-panel-items
			max-height: 140px !important
	&--block
		display: block
	&--integration
		justify-content: flex-end
		margin-top: 15px
		.btn
			width: 160px
	&--integration-cw
		.ng-select
			.ng-select-container
				width: 240px !important

.page-tile__group-email-template
	justify-content: flex-start
	.page-tile__group-input
		width: 450px
	.page-tile__group-label
		width: 100px
	.ng-select
		.ng-select-container
			width: 300px !important

.page-tile__group-long
	.page-tile__group-input
		width: 40px
	.page-tile__group-label
		width: 100%
		display: flex
		.ng-select
			.ng-select-container
				margin: 0 10px
				width: 50px !important
.page-tile__group-short
	display: flex
	.ng-select:first-child
		.ng-select-container
			width: 200px !important
	.invoice-date-first
		width: 100%
		display: flex
		justify-content: space-evenly
		align-items: center
		.ng-select
			.ng-select-container
				width: 100px !important
	.invoice-date-second
		width: 100%
		display: flex
		justify-content: space-evenly
		align-items: center

.page-tile__group-undo
	color: $secondary-color
	padding: 0
	padding-left: 3px

.page-tile__group--multiple-h
	.ng-select-container
		height: auto !important

.page-tile__group-extra
	display: flex
	&--multiple-h
		.ng-select-container
			height: auto !important
	.ng-select
		.ng-select-container
			width: 318px !important
	.extra-button
		transition: .2s
		width: 32px
		height: 28px
		border-radius: 0
		padding: 0
		border-color: transparent
		color: #999
		&:hover
			color: $secondary-color
		span
			font-size: 22px
		sup
			top: -0.4em

.page-tile__group-label
	color: #6d6e7a
	font: 12px "SF-medium"
	width: 30%
	line-height: 2.4
	&--40
		width: 40%
	&--60
		width: 60%

.page-tile__group-split
	.page-tile__group-input
		width: 85px

.page-tile__group-split-175
	.page-tile__group-input
		width: 175px

.page-tile__password-error
	position: absolute
	right: 0
	top: 38px
	font-weight: 500
	line-height: 0
	color: indianred

.page-tile__banner
	width: 125px
	height: 125px
	margin: 0 auto
	img
		width: 100%

.page-tile__group-input-percent
	display: flex
	line-height: 2
	width: 350px

	.percent
		transition: .2s all
		border-bottom: 1px solid #b0bec5
		&--invalid
			border-bottom-color: indianred !important

.page-tile__group-input
	padding: 0
	background: transparent
	border: none
	border-bottom: 1px solid #b0bec5 !important
	outline: none
	width: 350px
	border-radius: 0
	transition: .2s all
	font: 1em/1.6 "SF-medium"
	.checkbox-label
		line-height: inherit
	&--alias
		width: 319px
	&--picker[readonly], &--picker_[readonly]
		width: 317px
		border-bottom-color: #b0bec5 !important
		background-color: inherit
	&--picker_[readonly]
		&.ng-invalid
			border-bottom-color: indianred !important
	&--number
		text-align: right
	&--number-left
		text-align: left
	&--border-none
		border-bottom-color: transparent !important
	&:focus
		border-bottom-color: $secondary-color
	&.ng-invalid
		border-bottom-color: indianred !important
	&.ng-invalid:focus
		outline: none !important
		-webkit-box-shadow: none !important
		box-shadow: none !important

@mixin nth-children($points...)
	@each $point in $points
		&:nth-child(#{$point})
			@content

.text-placeholder
	width: 100%
	&-item
		height: 10px
		margin: 10px 0 !important
		animation: pulse 1s infinite ease-in-out

		div
			@include nth-children(1, 5, 9)
				width: 250px

				@include nth-children(2, 6, 10)
					width: 200px

				@include nth-children(3, 7)
					width: 150px

				@include nth-children(4, 8)
					width: 300px

@keyframes pulse
	0%
		background-color: rgba(165, 165, 165, 0.1)

	50%
		background-color: rgba(165, 165, 165, 0.3)

	100%
		background-color: rgba(165, 165, 165, 0.1)
