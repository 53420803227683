.btn
	font-family: inherit
	font-size: inherit
	background: none
	cursor: pointer
	padding: 8px 10px
	display: inline-block
	font-weight: 700
	outline: none
	position: relative
	vertical-align: middle
	text-align: center
	color: $white
	border: 1px solid transparent
	border-radius: 2px
	&--primary
		background-color: $primary-color
		border-color: $primary-color
	&--secondary
		background-color: $secondary-color
		border-color: $secondary-color
	&--default
		color: #333
		background-color: $white
		border-color: #ccc
	&--alias
		border: none
		transition: .2s
		color: #6d6e7a
		&:hover
			color: $secondary-color
	&--warning
		background-color: $color-warning
		border-color: #eea236
	&--error
		background-color: $color-error
		border-color: #d43f3a
	&--xero
		background: #00bce4
		background: -moz-linear-gradient(top, #00bce4 0%, #00a3c9 100%) !important
		background: -webkit-linear-gradient(top, #00bce4 0%,#00a3c9 100%) !important
		background: linear-gradient(to bottom, #00bce4 0%,#00a3c9 100%) !important
		border-radius: 20px
		color: white
		border: none
	&--uploadFile
		width: 36px
		height: 36px
		padding: 0
		.fa-upload
			width: 36px
			height: 36px
			line-height: 1.6
			cursor: pointer
			&:before
				font-size: 20px
				margin-right: 2px
	&--wise
		border: none
		padding: 0
		line-height: 0
		background: #07B9E4
		&:hover
			box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.15)
		img
			width: 36px
			border-radius: 2px
	&--add
		padding: 0 9px
		padding-bottom: 4px
		font-weight: 100
		line-height: 1
		font-size: 30px
	&--cog
		padding: 0
		padding-top: 2px
		margin-right: 10px !important
		.fa
			font-size: 20px
			color: $primary-color
	&--sync
		padding: 0
		font-size: 22px
	&--filter
		width: 196px !important
	&--bucket
		font-size: 19px
		width: 35px
		padding: 0
		line-height: 1.75
	&--action-bar
		margin-right: 5px
	&--feed-refresh
		width: 32px
		height: 32px
	&--compare
		padding: 8px 10px
		margin-bottom: -27px
		margin-left: 15px
		font-size: inherit
	&--my-details
		padding: 10px 0
		width: 190px
	&--import
		margin: 10px
		width: 100px
	&--upload, &--delete
		padding: 5px 0
		width: 176px
	&--delete
		margin-left: 48px
	&--save
		padding: .6em 0
		width: 150px
	&--extra
		width: 150px
		margin: 0 10px
	&--generate
		width: auto !important
		height: auto !important
	&--export
		padding: 8px
		&:before
			font-family: FontAwesome
			content: "\f064"
			font-size: 18px
	&--text
		border: none
		background: transparent
		color: #444
		font-weight: normal
		opacity: .7
		margin-right: 0 !important
		width: auto !important
		&:hover
			opacity: 1
			text-decoration: underline
	&--extra-field
		padding: 0px 5px
		margin-left: 10px
		width: 45px
		height: 25px
		border-radius: 5px
	&--p5_10
		padding: 5px 10px
.btn.disabled, .btn[disabled], fieldset[disabled] .btn
	cursor: not-allowed
	filter: alpha(opacity=65)
	-webkit-box-shadow: none
	box-shadow: none
	opacity: .65

.btn.disabled:hover
	opacity: .65

.btn:active, .btn.active
	background-image: none
	outline: 0
	-webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125)

.btn--text:active, .btn--text.active
	box-shadow: none

.btn--default:hover, .btn--warning:hover, .btn--error:hover, .btn--secondary:hover, .btn--primary:hover, .btn--xero:hover
	opacity: 0.9

.btn-spinner
	font-size: 15px
	margin-left: 5px
	color: #ddd

.btn-fixed
	position: fixed
	bottom: 210px
	padding-top: 10px
	margin-bottom: 0
	z-index: 998
	right: 10px
	ul
		left: 0
		right: 0
		text-align: center
		position: absolute
		bottom: 73px
		margin: 0
		list-style-type: none
		display: none
	li
		margin-bottom: 5px
		&:hover span
			display: block

	&:hover ul
		display: block
		-webkit-animation: fadein 0.5s
		-moz-animation: fadein 0.5s
		-o-animation: fadein 0.5s
		animation: fadein 0.5s
		-webkit-animation-fill-mode: forwards
		-moz-animation-fill-mode: forwards
		-o-animation-fill-mode: forwards
		animation-fill-mode: forwards

.btn-floating
	padding: 0
	border-radius: 50%
	width: 65.5px
	height: 65.5px
	margin-bottom: 5px
	box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15)
	user-select: none
	-moz-user-select: none
	-khtml-user-select: none
	-webkit-user-select: none
	-o-user-select: none
	i
		color: #fff
		font: 20px/65px "SF-regular"
	&--empty
		opacity: 0.5
	&--small
		right: 25px
		width: 37px
		height: 37px
		margin-bottom: 0
		i
			line-height: 38.5px
			margin-right: -1px
			font-size: 20px
			font-family: FontAwesome
	&:active
		box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18), 0 4px 15px 0 rgba(0,0,0,0.15)

.btn-floating__desription-wrapper
	width: 150px
	position: fixed
	margin-left: -190px
	margin-top: 7px
	display: flex
	justify-content: flex-end

.btn-floating__desription
	display: none
	padding: 2px 10px 2px 10px
	background-color: #f4f4f4
	border-radius: 4px
	opacity: 0

	-webkit-animation: fadein 0.3s
	-moz-animation: fadein 0.3s
	-o-animation: fadein 0.3s
	animation: fadein 0.3s
	-webkit-animation-fill-mode: forwards
	-moz-animation-fill-mode: forwards
	-o-animation-fill-mode: forwards
	animation-fill-mode: forwards
	box-shadow: 0 5px 6px 0 rgba(0,0,0,0.10), 0 2px 7px 0 rgba(0,0,0,0.10)

.round-left
	border-top-left-radius: 0
	border-bottom-left-radius: 0
.round-right
	border-top-right-radius: 0
	border-bottom-right-radius: 0
.round-bottom
	border-bottom-left-radius: 2px
	border-bottom-right-radius: 2px

.drop-down
	display: inline-block
	position: relative

.drop-down__menu-box
	position: absolute
	width: 170px
	left: 40px
	top: 0
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2)
	transition: all 0.2s
	-webkit-transition: all 0.2s
	-moz-transition: all 0.2s
	-o-transition: all 0.2s
	visibility: hidden
	opacity: 0
	z-index: 1
	border-radius: 2px
	&--horizontal
		display: flex
		box-shadow: none
		.drop-down__menu
			padding: 0
			width: 36px
			padding-left: 20px
			background: transparent
			&:hover
				background: transparent
			&:first-child
				padding-left: 17px
			.drop-down__item
				padding: 0
	&--invoice-actions
		width: 230px
		left: 0
		top: 2.7em
		.fa
			width: 15px
	&--generate
		width: 114px
		left: -78px
		top: 34px
	&--customers
		width: 190px
		left: 0px
		top: 38px

.drop-down__menu
	margin: 0
	padding: 0 13px
	list-style: none
	background-color: $primary-color
	&:hover
		background-color: #19B1C5

.drop-down__item
	font-size: 13px
	padding: 8px 0
	color: $white
	cursor: pointer

.drop-down--active .drop-down__menu-box
	visibility: visible
	opacity: 1

@keyframes fadein
	0%
		opacity: 0
	100%
		opacity: 1

@-moz-keyframes fadein
	0%
		opacity: 0
	100%
		opacity: 1

@-webkit-keyframes fadein
	0%
		opacity: 0
	100%
		opacity: 1

@-o-keyframes fadein
	0%
		opacity: 0
	100%
		opacity: 1
