@import "./variables"
@import "./fonts"
@import "./buttons"
@import "./app-spinner"
@import "./main-loader"

@import "./login"
@import "./layout"
@import "./navigation"
@import "./sidebar"
@import "./tooltip"

@import "./form"
@import "./email-tempates"
@import "./grid"
@import "./grid-columns"
@import "./dashboard"
@import "./page-tile"

@import "./chips"
@import "./modal"
@import "./modal-entity"
@import "./toast"
@import "./ng-select"
@import "./notifications"
@import "./period-picker"

@import "./context-menu"
@import "./notes"
@import "./media"

html, body
	height: auto
	min-height: 500px
	max-height: 100%
	min-width: 1000px
	margin: 0

dg-app-root
	height: 100%

body
	line-height: 1.6
	color: #444
	background-color: $body-background
	font: .875em/1.6 "SF-medium", Arial, serif
	overflow-x: hidden

table
	width: 100%
	border-collapse: collapse
	border-spacing: 0
	td, th
		padding: .5em 1em
		border-bottom: none
		border-right: none
		text-align: left
	a
		font-weight: normal

a
	color: #005e80
	text-decoration: none

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6
	font-family: inherit
	font-weight: 500
	line-height: 1.1
	color: inherit

h1, .h1, h2, .h2, h3, .h3
	margin-top: 15px
	margin-bottom: 15px

h4, .h4, h5, .h5, h6, .h6
	margin-top: 10px
	margin-bottom: 10px

h1, .h1
	font-size: 36px

h2, .h2
	font-size: 30px

h3, .h3
	font-size: 24px

h4, .h4
	font-size: 18px
	font-weight: 600

h5, .h5
	font-size: 14px

h6, .h6
	font-size: 12px

label
	font-weight: bold

blockquote
	background: #f7f7f7
	padding: 15px
	margin: 0

hr
	border: 0
	height: 0
	border-top: 1px solid rgba(0, 0, 0, 0.1)
	border-bottom: 1px solid rgba(255, 255, 255, 0.3)

.datepicker__smart
	margin-right: 20px
	.ng-select
		width: 250px
	.ng-select .ng-select-container
		padding: 0.5em 0
		margin-bottom: .4em
	.ng-select .ng-select-container .ng-value-container .ng-value
		color: #6d6e7a
		padding-left: 12px
	.datepicker__smart-span
		display: flex
		justify-content: space-between
		span
			color: darkgrey

.datepicker__smart--flex
	display: flex
	width: 430px
	margin: 0 auto
	.datepicker__smart-label
		font-weight: 500
		color: #6d6e7a
		line-height: 2.3
		margin-right: 15px
.datepicker__smart--flex-invoices
	display: flex
	align-items: baseline
	margin: 0 20px 0 0

.custom-date-filter
	a
		position: absolute
		right: 20px
		color: rgba(0, 0, 0, 0.54)
		cursor: pointer
	input
		width: 200px
		padding-left: 30px
		border-bottom: 0 !important
	&:after
		position: absolute
		content: '\f073'
		display: block
		font-weight: 400
		font-family: FontAwesome
		left: 10px
		pointer-events: none
		color: rgba(0, 0, 0, 0.54)

select
	width: 100%
	overflow: hidden
	box-shadow: none
	-webkit-appearance: none
	cursor: pointer

	/* Hide arrow on FF */
	-moz-appearance: none
	text-indent: 0.01px
	text-overflow: ellipsis

	position: relative
	border: 1px solid #ddd
	border-radius: 3px
	/* <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11"><path d="M4.33 8.5L0 1L8.66 1z" fill="#666"/></svg> */
	background: #fcfcfc url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2211%22%20height%3D%2211%22%20viewBox%3D%220%200%2011%2011%22%3E%3Cpath%20d%3D%22M4.33%208.5L0%201L8.66%201z%22%20fill%3D%22%23666%22%2F%3E%3C%2Fsvg%3E') right 5px center no-repeat
	background-size: 10px
	padding: 8px 0 8px 15px
	font-family: Arial, sans-serif
	color: #666
	&:after
		content: ''
		position: absolute
		width: 1px
		top: 0
		bottom: 0
		right: 40px
		border-left: 1px solid #ddd

input, textarea
	width: 100%
	border: 1px solid #ddd
	border-radius: 3px
	color: #666
	padding: .5em 1em
	background: #fcfcfc
	font-family: Arial, sans-serif
	margin: 0
	box-sizing: border-box

input[type="file"], input[type="radio"]
	display: none

.label
	display: inline-block
	padding: 0.1em .4em .1em
	border-radius: 3px
	color: white
	font-size: 90%
	min-width: 70px
	&--success
		background-color: $color-success
	&--success-custom
		background-color: $color-success-custom
	&--warning
		background-color: $color-warning
	&--error
		background-color: $color-error
	&--grey
		color: rgba(0, 0, 0, 0.8)
		background-color: rgba(0, 0, 0, 0.05)
	&--default
		border: 1px solid rgba(0, 0, 0, 0.2)
		background-color: transparent
		color: rgba(0, 0, 0, 0.8)
	&--status
		min-width: 80px
		line-height: 2
		text-align: center

.fa-spins
	animation: fa-spin 1s infinite linear

.save-label
	min-width: 35px
	height: 17px
	text-align: center
	font-size: 80%
	background-color: $color-success
	margin-top: 3px
	-webkit-animation: fadeout 5s linear forwards
	animation: fadeout 5s linear forwards
	opacity: 0

@-webkit-keyframes fadeout
	0%
		opacity: 1
	100%
		opacity: 0

@keyframes fadeout
	0%
		opacity: 1
	100%
		opacity: 0

.disable-select
	-webkit-touch-callout: none
	/* iOS Safari */
	-webkit-user-select: none
	/* Safari */
	-moz-user-select: none
	/* Firefox */
	-ms-user-select: none
	/* Internet Explorer/Edge */
	user-select: none
	&:hover
		border-color: #ddd !important

.single-field-fieldset
	padding-left: 0
	padding-right: 0

fieldset
	border: none
	padding: 0
	margin: 0

fieldset[disabled]
	label.checkbox-label:before
		background: #ddd
	input, select
		background-color: #f5f5f5 !important
	.ng-select.ng-select-single .ng-select-container .ng-value-container, .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-value
		z-index: 1 !important

input[readonly], select[readonly], textarea[readonly]
	background-color: #f5f5f5
	border-bottom-color: transparent !important

input.ng-invalid:not(.ng-untouched)
	border-color: #a94442

input.ng-invalid:focus
	outline: none !important
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #ce8483

.message-error
	color: #a94442
	font-size: 12px
	&--export
		font-size: 14px
		position: absolute
		top: 5px
		right: -45%

.documentation__links
	display: grid
	background-color: #eee
	border: 1px solid #ABA

.c0
	font-size: 12pt
	font-family: "Courier New"
	margin: 16px 0
	padding: 8px
.fs-16
	font-size: 16px !important
.fs-18
	font-size: 18px !important
.fs-20
	font-size: 20px !important
.z-index-2
	z-index: 2 !important
.fa-asterisk
	font-size: 6px !important
	position: absolute
	margin-top: 5px
	margin-left: 3px
	color: indianred

.radiobox
	width: 100%
	margin: 0
	padding: 0
	.radiobox-label:before
		cursor: pointer
		display: inline-block
		font: 13px/1em sans-serif
		padding: 0
		margin: 3px
		width: 14px
		height: 14px
		vertical-align: top
		text-align: center
		border-radius: 100%
		border: 2px solid #e0e0e0
		content: "\00a0"
		background: white

.radiobox input[type="radio"]:checked:checked + .radiobox-label:before
	background-color: #e0f9fc
	content: "\25CF"
	color: #00a9c0
	line-height: 0.9
	font-size: 14px

.checkbox, .checkbox--checked, .checkbox--grid
	label.checkbox-label
		display: inline-block
		cursor: pointer
		position: relative
		margin: 0

	label.checkbox-label:before
		display: inline-block
		font: 13px/1em sans-serif
		padding: 0
		margin: 3px
		width: 14px
		height: 14px
		vertical-align: top
		text-align: center
		border: 2px solid #e0e0e0
		content: ""
		background: white

	input[type=checkbox] + label:before
		font-family: FontAwesome

	input[type="checkbox"].checkbox-label:checked + label.checkbox-label:before
		background-color: #e0f9fc
		border: 2px solid transparent
		content: "\f00c"
		color: #00a9c0

	input[type="checkbox"].checkbox-label
		position: absolute
		width: 18px !important
		height: 18px !important
		margin: 0
		border: 1px solid transparent
		margin-top: 3px
		margin-left: 3px

.checkbox--grid
	label.checkbox-label:before
		width: 17px !important
		height: 17px !important

.tag-row-wrapper
	border: 1px solid transparent
	border-radius: 7px
	padding: .3px 0.7px
	&--error
		.ng-select-container
			border-color: indianred !important

.close-button
	margin-left: 10px
	font-size: 28px
	color: #CCC
	border: none
	background: transparent
	cursor: pointer
	outline: none
	transition: 0.3s
	box-shadow: none
	padding: 0
	&:hover
		color: #7faebf
.height_275
	height: 275px
.side-menu__item--active
	color: #30d6e5 !important
	.icon-invoice-dollar
		background-image: url(/assets/icons/file-invoice-dollar-solid-active.svg) !important
	.side-menu_ipnd-icon
		background-image: url(/assets/icons/ipnd_icon_active.png) !important

.side-menu__content--open
	.header__content--fixed
		.breadcrumbs
			margin-left: 199px !important
	.page__panel-arrow--left
		left: 150px !important
		&:hover
			left: 170px !important

.ag-body-container
	margin-bottom: 0 !important

.icon-invoice-dollar
	background-image: url(/assets/icons/file-invoice-dollar-solid.svg)
	width: 18px
	height: 24px
	background-size: cover
	margin-right: 11px
	display: inline-block
	vertical-align: bottom

.cell-span
	background-color: white
	pointer-events: none
	border-bottom: 1px solid #d9dcde !important
	border-right: 1px solid #d9dcde !important

.pointer-event__none
	pointer-events: none

.text-align-c
	text-align: center !important

.block
	display: block !important

.flex
	display: flex !important

.flex-column
	display: flex !important
	flex-direction: column !important

.flex-start
	justify-content: flex-start !important

.flex-align-center
	align-items: center !important

.flex-end-c
	display: flex
	justify-content: flex-end !important
	align-items: center

.flex-center
	justify-content: center !important

.flex-start-s
	display: flex
	justify-content: flex-start
	align-items: center

.flex-between
	display: flex
	justify-content: space-between
	align-items: center

.space-between
	justify-content: space-between

.border-none
	border: none

.margin__20
	margin: 20px !important

.margin-0
	margin: 0 !important

.margin-t__0
	margin-top: 0px !important

.margin-t__5
	margin-top: 5px !important

.margin-r__5
	margin-right: 5px !important

.margin-r__10
	margin-right: 10px !important

.margin-l__-15
	margin-left: -15px !important

.margin-l__5
	margin-left: 5px !important

.margin-l__10
	margin-left: 10px !important

.margin-l__20
	margin-left: 20px !important

.margin-r__20
	margin-right: 20px !important

.margin-r__40
	margin-right: 40px !important

.margin-t__10
	margin-top: 10px !important

.margin-t__15
	margin-top: 15px !important

.margin-t__20
	margin-top: 20px !important

.margin-t__25
	margin-top: 25px !important
.margin-t__40
	margin-top: 40px !important
.margin-t__-5
	margin-top: -5px !important

.margin-t__-10
	margin-top: -10px !important

.margin-t__-15
	margin-top: -15px !important

.margin-t__-20
	margin-top: -20px !important

.margin-t__-65
	z-index: 9
	margin-top: -65px !important

.margin-b__0
	margin-bottom: 0 !important

.margin-b__10
	margin-bottom: 10px !important

.margin-b__20
	margin-bottom: 20px !important

.height__100
	height: 100% !important
.height__auto
	height: auto !important

.padding__20
	padding: 20px
	&--y
		padding: 20px 0

.padding-h__15
	padding: 0
	padding: 15px 0

.padding-w__20
	padding: 0
	padding: 0 20px

.padding-0
	padding: 0 !important

.padding-5
	padding: 5px !important

.padding-b__15
	padding-bottom: 15px !important

.padding-b__20
	padding-bottom: 20px !important

.padding-b__30
	padding-bottom: 30px !important

.padding-b__70
	padding-bottom: 70px !important

.padding-b__150
	padding-bottom: 150px !important

.padding-b__180
	padding-bottom: 180px !important

.padding-t__20
	padding-top: 20px

.padding-t__10
	padding-top: 10px !important

.width-auto
	width: auto !important

.width-10p
	width: 10% !important
.width-40p
	width: 40% !important

.width-47p
	width: 47% !important

.width-50p
	width: 50% !important
.width-54p
	width: 54% !important
.width-60p
	width: 60% !important
.width-70p
	width: 70% !important
.width-75p
	width: 75% !important
.width-80p
	width: 80% !important

.width-100p
	width: 100% !important

.width-100px
	width: 100px
.width-110px-i
	width: 110px !important
.width-150px
	width: 150px
.width-200px
	width: 200px !important
.width-240px
	width: 240px
	input
		padding: 5px !important
		width: 200px !important
.width-280px
	width: 280px !important
.width-300px
	width: 300px !important
.width-310px
	width: 310px !important
.width-350px
	width: 350px !important
.width-450px
	width: 450px !important
.width-550px
	width: 550px !important
.max-h100
	max-height: 100% !important
.md-drppicker
	margin-top: 0 !important
	left: auto !important
	td,th
		border-radius: 3px !important
		height: 25px !important
		width: 25px !important
	.calendar
		td,th
			min-width: 25px !important
	td.active, td.active:hover
		background-color: transparent !important
		border-bottom: 1px solid $secondary-color !important
		color: #333333 !important
	.table-condensed
		thead
			tr:last-child
				display: none !important
	.btn
		color: white !important
		border-radius: 0 !important
		margin-right: 5px !important
		border: 1px solid #f55a24 !important
		background-color: #f55a24 !important
		text-transform: capitalize !important
		box-shadow: none !important
		width: 180px !important
		&:before
			background-color: transparent !important
	.btn.btn-default
		color: #333 !important
		border-radius: 0 !important
		margin-right: 25px !important
		background-color: #fff !important
		border: 1px solid #ccc !important
		text-transform: capitalize !important
		box-shadow: none !important
		width: 185px !important

.md-drppicker.shown, .md-drppicker.hidden
	transition: 0s !important

.zendeskCloseButton
	z-index: 999
	transform: translateZ(0px)
	position: fixed
	right: 15px
	display: block
	opacity: 1
	bottom: 85px
	transition: all .5s
	button
		background-color: #30AABC !important
		color: white
		border: none
		outline: none
		cursor: pointer
		border-radius: 50%
		padding: 2px 5px
		font-size: 14px

.dg-toast
	white-space: -moz-pre-wrap
	white-space: -pre-wrap
	white-space: -o-pre-wrap
	white-space: pre-wrap
	word-wrap: break-word

ui-switch
	.switch.switch-medium > .switch-pane > span
		font-size: 14px
		font-family: SF-medium, Arial, serif
