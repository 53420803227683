.modal
	&--entity
		width: 658px
		top: 7%
		left: calc(50% - 329px)
		.modal__content
			border: none
			border-radius: 5px
			.modal__content-header
				border-top-left-radius: 5px
				border-top-right-radius: 5px
			.modal__content-body
				padding: 0
				border-bottom-left-radius: 5px
				border-bottom-right-radius: 5px
		textarea, select
			height: 32px
			resize: none
		.ng-control
			min-height: 32px
			.ng-value-container
				padding-left: 15px
			.ng-input
				input
					padding-left: 5px

.form
	&--entity
		width: 100%
		.period-picker__input
			height: 32px
		.form__content
			margin: 0
			height: 400px
			overflow: auto
			box-sizing: content-box
			padding: 15px 0
		.form__group-label, .checkbox-label
			line-height: inherit
		.form__group
			margin: 0 auto
			position: relative
			padding-left: 15px
			.checkbox
				.checkbox-label
					margin: 0
					margin-top: 10px
				label.checkbox-label:before
					margin: 0
					margin-right: 10px
				input[type="checkbox"].checkbox-label
					margin: 0
					margin-top: 10px
			&--separator
				box-shadow: 0 9px 14px -8px rgba(201, 189, 201, 0.55)
				padding: 10px 20px
				margin-bottom: 10px
				background: #F6F6F6
				&:first-child
					margin-top: -15px
				.form__group-item
					padding: 0 !important
			.form__group-item--x1, .form__group-item--x2
				width: 270px
				padding-bottom: 10px
				padding-left: 20px
				text-align: left
			.form__group-item--textareaFull
				width: 575px
				padding: 0 0 10px 20px
		.form__bottom
			margin: 0
			padding: 10px 17px
			background-color: #f4f4f4
			border: none
			justify-content: flex-end
			border-top: 1px solid rgba(0, 0, 0, 0.1)
			border-bottom-left-radius: 5px
			border-bottom-right-radius: 5px
			.modal__bottom-button
				font-weight: normal
				width: 196px
				margin-left: 20px
