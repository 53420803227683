.page-tile__area
	resize: none
	background: transparent
	border: 1px solid transparent
	outline: none
	padding: 7px 10px
	height: auto
	overflow: hidden
	&:hover
		border-color: transparent
	&--edit
		border-color: rgba(0, 0, 0, 0.1)
		&:hover
			border-color: rgba(0, 0, 0, 0.1)

.page-tile__notes
	.page-tile__area
		margin-top: 15px
.page-tile__notes-header
	margin-top: 10px
	span
		margin-left: 15px
		color: rgb(66, 82, 110)
		&:nth-child(1)
			margin-left: 10px
			font-weight: 600
		&:nth-child(3)
			color: rgb(107, 119, 140)

.page-tile__notes-footer
	width: 100%
	height: 45px
	border-bottom: 1px solid rgba(0, 0, 0, 0.1)
	.btn
		margin-right: 10px
		width: 80px

.fa-sticky-note-o
	font-size: 16px
	color: #00a9c0
