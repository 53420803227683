/** Regular
@font-face
	font-family: "SF-regular"
	font-weight: 400
	src: local("SF-regular"), url('/assets/fonts/sf-regular.woff') format("woff")

/** Medium
@font-face
	font-family: "SF-medium"
	font-weight: 500
	src: local("SF-medium"), url('/assets/fonts/sf-medium.woff') format("woff")

/** Semi Bold
@font-face
	font-family: "SF-semiBold"
	font-weight: 600
	src: local("SF-semiBold"), url('/assets/fonts/sf-semibold.woff') format("woff")

/** Bold
@font-face
	font-family: "SF-bold"
	font-weight: 700
	src: local("SF-bold"), url('/assets/fonts/sf-bold.woff') format("woff")
