.period-picker
	min-height: 20px
	padding: 5px
	width: 25%
	background-color: #f5f5f5
	border: 1px solid #e3e3e3
	border-radius: 4px
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05)

.period-picker__resetbtn
	color: $secondary-color
	right: 30px
	opacity: 0.7
	font-weight: 700
	font-size: 15px
	position: absolute
	cursor: pointer
	line-height: 2
	outline: none
	border: none
	background: transparent

.period-picker__dropdown
	position: relative
	&--open
		.period-picker__group
			display: block
.period-picker__label
	width: 65px

.period-picker__input
	display: flex
	width: 100%
	.period-picker__input-field
		border-top-right-radius: 0
		border-bottom-right-radius: 0
	.period-picker__input-icon
		border-radius: 4px
		border-top-left-radius: 0
		border-bottom-left-radius: 0
		padding: 0 10px
		border: 1px solid #ddd
		border-left: 0
		background-color: #eee

.period-picker__group
	position: absolute
	top: -13em
	width: 230px
	height: 185px
	z-index: 1000
	display: none
	min-width: 160px
	padding: 5px 0
	margin: 2px 0 0
	background-color: #fff
	border: 1px solid rgba(0, 0, 0, .15)
	border-radius: 2px
	box-shadow: 0 1px 10px rgba(0, 0, 0, .175)
	&--filter
		button
			margin-right: inherit !important
			width: inherit !important
			height: inherit !important
	&--left
		left: 0
	&--left-15
		left: 15em
		top: 2em !important
	&--left-15_
		left: 29.5em
		top: -12em
	&--right
		right: 0
	&--bottom
		top: 3em !important

	.period-picker__group-header
		display: flex
		justify-content: center
		padding: 5px 0
		.period-picker__group-header-title
			line-height: 1.5
			-webkit-user-select: none
			-moz-user-select: none
			-ms-user-select: none
			user-select: none
			padding: 0 25px
			border: 0
			cursor: pointer
			&:hover
				background: #eee
				border-radius: 3px
		.period-picker__group-header-arrow
			color: #6d6e7a
			border: none
			background: none
			font-size: 16px
			padding: 0 5px

.period-picker__grid
	padding: 0 5px
	display: grid
	display: -ms-grid
	grid-template-rows: 50px 50px 50px
	-ms-grid-rows: 50px 50px 50px

	grid-template-columns: 55px 55px 55px 55px
	-ms-grid-columns: 55px 55px 55px 55px

	.period-picker__grid-cell
		padding: 10px
		line-height: 33px
		cursor: pointer
		color: #6d6e7a
		text-align: center
		-webkit-touch-callout: none
		-webkit-user-select: none
		-moz-user-select: none
		-ms-user-select: none
		user-select: none
		&--inactive
			color: lightgrey
			&:hover
				background: transparent !important
		&--current
			border-bottom: 1px solid coral
			&:hover
				background: transparent !important
		&:hover
			background: #eee
			border-radius: 3px
	&--days
		padding: 0 12px
		grid-template-rows: 25px 25px 25px 25px 25px 25px
		-ms-grid-rows: 25px 25px 25px 25px 25px 25px

		grid-template-columns: 30px 30px 30px 30px 30px 30px 30px
		-ms-grid-columns: 30px 30px 30px 30px 30px 30px 30px
		.period-picker__grid-cell
			width: 25px
			height: 25px
			padding: 0
			line-height: 25px
	&--days > :nth-child(2)
		-ms-grid-column: 2
		-ms-grid-row: 1
	&--days > :nth-child(3)
		-ms-grid-column: 3
		-ms-grid-row: 1
	&--days > :nth-child(4)
		-ms-grid-column: 4
		-ms-grid-row: 1
	&--days > :nth-child(5)
		-ms-grid-column: 5 !important
		-ms-grid-row: 1 !important
	&--days > :nth-child(6)
		-ms-grid-column: 6 !important
		-ms-grid-row: 1 !important
	&--days > :nth-child(7)
		-ms-grid-column: 7 !important
		-ms-grid-row: 1 !important
	&--days > :nth-child(8)
		-ms-grid-column: 1 !important
		-ms-grid-row: 2 !important
	&--days > :nth-child(9)
		-ms-grid-column: 2 !important
		-ms-grid-row: 2 !important
	&--days > :nth-child(10)
		-ms-grid-column: 3 !important
		-ms-grid-row: 2 !important
	&--days > :nth-child(11)
		-ms-grid-column: 4 !important
		-ms-grid-row: 2 !important
	&--days > :nth-child(12)
		-ms-grid-column: 5 !important
		-ms-grid-row: 2 !important
	&--days > :nth-child(13)
		-ms-grid-column: 6
		-ms-grid-row: 2
	&--days > :nth-child(14)
		-ms-grid-column: 7
		-ms-grid-row: 2
	&--days > :nth-child(15)
		-ms-grid-column: 1
		-ms-grid-row: 3
	&--days > :nth-child(16)
		-ms-grid-column: 2
		-ms-grid-row: 3
	&--days > :nth-child(17)
		-ms-grid-column: 3
		-ms-grid-row: 3
	&--days > :nth-child(18)
		-ms-grid-column: 4
		-ms-grid-row: 3
	&--days > :nth-child(19)
		-ms-grid-column: 5
		-ms-grid-row: 3
	&--days > :nth-child(20)
		-ms-grid-column: 6
		-ms-grid-row: 3
	&--days > :nth-child(21)
		-ms-grid-column: 7
		-ms-grid-row: 3
	&--days > :nth-child(22)
		-ms-grid-column: 1
		-ms-grid-row: 4
	&--days > :nth-child(23)
		-ms-grid-column: 2
		-ms-grid-row: 4
	&--days > :nth-child(24)
		-ms-grid-column: 3
		-ms-grid-row: 4
	&--days > :nth-child(25)
		-ms-grid-column: 4
		-ms-grid-row: 4
	&--days > :nth-child(26)
		-ms-grid-column: 5
		-ms-grid-row: 4
	&--days > :nth-child(27)
		-ms-grid-column: 6
		-ms-grid-row: 4
	&--days > :nth-child(28)
		-ms-grid-column: 7
		-ms-grid-row: 4
	&--days > :nth-child(29)
		-ms-grid-column: 1
		-ms-grid-row: 5
	&--days > :nth-child(30)
		-ms-grid-column: 2
		-ms-grid-row: 5
	&--days > :nth-child(31)
		-ms-grid-column: 3
		-ms-grid-row: 5
	&--days > :nth-child(32)
		-ms-grid-column: 4
		-ms-grid-row: 5
	&--days > :nth-child(33)
		-ms-grid-column: 5
		-ms-grid-row: 5
	&--days > :nth-child(34)
		-ms-grid-column: 6
		-ms-grid-row: 5
	&--days > :nth-child(35)
		-ms-grid-column: 7
		-ms-grid-row: 5
	&--days > :nth-child(36)
		-ms-grid-column: 1
		-ms-grid-row: 6
	&--days > :nth-child(37)
		-ms-grid-column: 2
		-ms-grid-row: 6
	&--days > :nth-child(38)
		-ms-grid-column: 3
		-ms-grid-row: 6
	&--days > :nth-child(39)
		-ms-grid-column: 4
		-ms-grid-row: 6
	&--days > :nth-child(40)
		-ms-grid-column: 5
		-ms-grid-row: 6
	&--days > :nth-child(41)
		-ms-grid-column: 6
		-ms-grid-row: 6
	&--days > :nth-child(42)
		-ms-grid-column: 7
		-ms-grid-row: 6

.period-picker__grid > :nth-child(2)
	-ms-grid-column: 2
	-ms-grid-row: 1

.period-picker__grid > :nth-child(3)
	-ms-grid-column: 3
	-ms-grid-row: 1

.period-picker__grid > :nth-child(4)
	-ms-grid-column: 4
	-ms-grid-row: 1

.period-picker__grid > :nth-child(5)
	-ms-grid-column: 1
	-ms-grid-row: 2

.period-picker__grid > :nth-child(6)
	-ms-grid-column: 2
	-ms-grid-row: 2

.period-picker__grid > :nth-child(7)
	-ms-grid-column: 3
	-ms-grid-row: 2

.period-picker__grid > :nth-child(8)
	-ms-grid-column: 4
	-ms-grid-row: 2

.period-picker__grid > :nth-child(9)
	-ms-grid-column: 1
	-ms-grid-row: 3

.period-picker__grid > :nth-child(10)
	-ms-grid-column: 2
	-ms-grid-row: 3

.period-picker__grid > :nth-child(11)
	-ms-grid-column: 3
	-ms-grid-row: 3

.period-picker__grid > :nth-child(12)
	-ms-grid-column: 4
	-ms-grid-row: 3
