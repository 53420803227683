.notification
	width: 360px
	right: -25px
	position: absolute
	margin-top: 12px
	background-color: transparent
	-webkit-box-shadow: 0 0 10px -3px rgba(0,0,0,0.2)
	-moz-box-shadow: 0 0 10px -3px rgba(0,0,0,0.2)
	box-shadow: 0 0 10px -3px rgba(0,0,0,0.2)

.notification__container
	position: relative
	z-index: 9

.notification__list
	list-style: none
	padding: 0
	margin: 0
	height: auto
	max-height: 85vh
	overflow: auto

.notification__group
	padding-top: 10px
	padding-left: 7px
	position: relative
	display: block
	background-color: $white
	border-top: 1px solid transparent
	border-bottom: 1px solid transparent
	.arrow-up
		border-bottom: transparent
	.notification__group-icon
		position: absolute
		font-size: 2em
		margin-top: -5px
		margin-left: 10px
	&--tools
		display: flex
		justify-content: space-between
		align-items: center
		padding: 5px
		padding-left: 18px
		border-bottom: none
		span
			color: #6d6e7a
			font-size: 13px
	&--empty
		padding: 15px
		text-align: center
	.notification__group-label
		margin: 0 auto
		margin-bottom: 15px
		width: 250px
		&--time
			color: #6d6e7a
			font-size: 12px
		&--title
			display: flex
			font-size: 11px
			color: #868686
		&--text
			font-weight: normal
			max-height: 60px
			overflow: hidden
			white-space: normal
			line-height: 15px
			font-size: $fsize-s
			color: #30334d
		&--bar
			width: 100%
			height: 10px
			overflow: visible
			margin-top: 2px
			.progress__bar
				float: left
				width: 100%
				height: 100%
				-webkit-transition: width .6s ease
				-o-transition: width .6s ease
				transition: width .6s ease
.arrow-up
	position: absolute
	right: 25px
	top: -7px
	width: 0
	height: 0
	display: none
	border-left: 7px solid transparent
	border-right: 7px solid transparent
	border-bottom: 7px solid #dbf8e6
	&--success
		border-bottom: 7px solid #dbf8e6
	&--info
		border-bottom: 7px solid #9be7ff
	&--warning
		border-bottom: 7px solid #f4c03c
	&--error
		border-bottom: 7px solid #ffbb93
	&--progress
		border-bottom: 7px solid white
	&--first
		display: block !important

.notification__group--error
	background-color: #ffbb93
	border-top-color: rgba(0, 0, 0, 0.1)
	border-bottom-color: #ffbb93
	.notification__group-icon
		.symbol:before
			content: '\26A0'
			color: #c75b39
.notification__group--info
	background-color: #9be7ff
	border-top-color: rgba(0, 0, 0, 0.1)
	border-bottom-color: #9be7ff
	.notification__group-icon
		.symbol:before
			content: '\1F6C8'
			color: #4bacb8
.notification__group--success
	background-color: #dbf8e6
	border-top-color: rgba(0, 0, 0, 0.1)
	border-bottom-color: #dbf8e6
	.notification__group-icon
		.symbol:before
			content: '\2713'
			color: #62c688
.notification__group--warning
	background-color: #f4c03c
	border-top-color: rgba(0, 0, 0, 0.1)
	border-bottom-color: #f4c03c
	.notification__group-icon
		.symbol:before
			content: '\26A0'
			color: #256d76

.header__new-notifications
	height: 13px
	width: 13px
	background: red
	border-radius: 15px
	position: absolute
	top: -5px
	right: -6px
	text-align: center
	font: 10px/1.5 "SF-bold"
	color: $white

.header__button
	cursor: pointer
	outline: none
	border: none
	padding: 0
	margin: 0
	background: 0 0
	color: inherit
	display: flex
	position: relative
	height: 100%
	align-items: center
	justify-content: center
	i
		color: white
		font-size: $fsize-l
