@media (max-width: 1280px)
	.page-tile--modal
		max-height: 600px
	.page
		.grid
			.grid-cell--hide
				display: none

		.grid--products
			.grid__header
				grid-template-columns: 5% 31% 7% 15% 15% 10% 7% 10%
				-ms-grid-columns: 5% 31% 7% 15% 15% 10% 7% 10%
			.grid__body
				.grid__body-row
					grid-template-columns: 5% 31% 7% 15% 15% 10% 7% 10%
					-ms-grid-columns: 5% 31% 7% 15% 15% 10% 7% 10%

	.btn-floating__desription-wrapper
		margin-top: 0 !important

	.btn-floating__desription
		position: fixed
		right: 75px
		margin-top: 7px

	.login--customize
		.login__item
			&--first
				width: 35% !important
			&--second
				width: 65% !important
				display: flex !important

	.login__item
		&--first
			width: 100% !important
		&--second
			display: none !important
		.page-tile__body
			&:nth-child(2)
				width: 500px

	.ag-theme-material
		max-width: 1172px !important
	.page__content-setting-item:last-child
		margin-right: 17px

$page_width: 100%

@media (min-width: 1281px)
	.grid
		.grid__header
			.grid__header-double-cell
				display: none
		.grid__body
			.grid__body-row
				.grid__body-double-cell
					display: none

	.page__form--customer-details
		.page__form-content
			.page__form-content_item_l
				.page__form-content_item--checkbox
					.page__form-content_item-input
						margin-left: 49px
	.form__group-item--step2_1
		.form__group-select-radio
			width: 600px !important

@media (min-width: 1281px) and (min-height: 901px)
	.modal--extra
		.modal__content
			.modal__content-body
				max-height: 750px !important

@media (min-width: 1240px)
	.page-tile_group
		display: flex

@media (min-width: 1281px) and (max-width: 1366px)
	.page-tile--modal
		max-height: 600px
	.ag-theme-material
		max-width: 1263px !important

@media (min-width: 1367px) and (max-width: 1440px)
	.page-tile--modal
		max-height: 600px
	.ag-theme-material
		max-width: 1335px !important

@media (min-width: 1441px) and (max-width: 1536px)
	.page-tile--modal
		max-height: 600px
	.ag-theme-material
		max-width: 1430px !important

@media (min-width: 1537px) and (max-width: 1600px)
	.page-tile--modal
		max-height: 600px

	.ag-theme-material
		max-width: 1495px !important

@media (min-width: 1601px) and (max-width: 1920px)
	.ag-theme-material
		max-width: 1815px !important
