$modal-width: 390px
.modal
	width: $modal-width
	margin: 0 auto
	position: fixed
	top: 25%
	left: calc(50% - (#{$modal-width} / 2))
	z-index: 102
	&-two-factor
		width: 335px

	.modal__content
		position: relative
		background-color: $white
		-webkit-background-clip: padding-box
		background-clip: padding-box
		border: 1px solid rgba(0,0,0,.2)
		outline: 0
		.modal__content-header
			background-color: #dcf0f5
			border-bottom: 1px solid #e5e5e5
			min-height: 40px
			line-height: 1.6
			padding: 3px 0
			padding-left: 20px
			display: flex
			align-items: center
			justify-content: space-between
			.modal__content-header-title
				font-size: $fsize-m
				color: #282a40
				word-break: break-word
			.modal__content-header-closebtn
				margin-right: 20px
				line-height: 3.4
				color: $secondary-color
				font-size: $fsize-l
				cursor: pointer
				opacity: 0.8
				&:hover
					opacity: 1

		.modal__content-body
			position: relative
			padding: 20px
			border-bottom: 1px solid #e5e5e5
			max-height: 250px

		.confirm
			overflow: auto

		.modal__content-footer
			display: flex
			justify-content: center
			padding: 13px 0
			.modal__content-footer-button
				width: 100px
				padding: 9px
				margin: 0 20px

		.modal__content-label
			margin: 0 auto
			margin-top: -10px
			width: 280px

		.modal__content-label:last-child
			margin-bottom: 10px

.modal--warning
	.modal__content
		.modal__content-header
			background: $color-warning
			.modal-title:before
				content: '\26A0'

.modal--inv-actions
	width: 430px
	left: calc(50% - 215px)
	.modal__content
		.modal__content-header
			height: 5px
			background: white
			justify-content: flex-end
			border: none
			.modal__content-header-closebtn
				position: relative
				z-index: 1
				line-height: 2.4

.modal--error
	.modal__content
		.modal__content-header
			background: $color-error
			.modal-title:before
				content: '\FF01'

.modal--grid-trt
	min-width: 990px
	position: absolute
	top: 10%
	left: calc(50% - 445px)
	.modal__content
		background: #f4f4f4
		.modal__content-body
			padding: 0 20px
			border: none
			.page-tile__panel
				margin: 13px 0
				top: 0
		.modal__content-footer
			justify-content: flex-end

.modal--insert
	min-width: 990px
	position: absolute
	top: 10%
	left: 50%
	.modal__content
		width: 100%
		left: -50%
		position: relative
		z-index: 102
		border: 1px solid #ddd
		.grid
			margin-top: 0
			.grid__footer
				margin: 0 20px
.modal--progress
	width: 700px
	position: absolute
	top: 10%
	left: calc(50% - 350px)
	.modal__content
		.modal__content-footer
			justify-content: flex-end
			padding: 13px 20px

.modal--confirmation
	.page-tile__body-proceed
		padding: 0 20px 15px
		border-bottom: 1px solid #e5e5e5
	.form__bottom
		border: none
		padding: 0 0 15px
		justify-content: space-between !important
		width: 250px
		margin: 0 auto !important
	.page-tile__body
		padding: 0
		max-height: 500px
		width: auto
	.page-tile__title
		background-color: #dcf0f5
		border-bottom: 1px solid #e5e5e5
		padding: 10px 20px
		box-sizing: border-box
	.page-tile__content
		max-height: 300px
		overflow: auto
		padding: 0 20px
		&-item
			margin: 5px 0
		&-more
			cursor: pointer
			margin-top: 7px
		&-row
			display: flex
			align-items: center
	.fa-circle
		font-size: 8px
		margin: 0 5px

.modal--tile
	top: 5%
	width: 600px
	left: calc(50% - 300px)
	border: none
	.modal__content
		border: none
		background: transparent
		.modal__content-body
			border: none

.modal--extra, .modal--tag
	top: 15%
	left: calc(50% - 195px)
	z-index: 100
	border: 1px solid rgba(0,0,0,.2)
	.modal__content
		z-index: 100
		border: none
		.modal__content-body
			overflow: auto
			height: auto
			max-height: 400px
			padding: 0
			border: 0

		.modal__content-group
			display: block
			width: 280px
			margin: 10px auto
			outline: none
			padding: 0
			border-bottom: none
			.modal__content-group-button
				margin-left: 10px
				border-radius: 4px
				padding: 0
				height: 25px
				width: 25px
				font-size: 15px
				line-height: 0
				font-weight: 200
				&--add
					width: 100%
					border-radius: 2px
					margin-left: 0
					height: 30px
					font-size: 25px
				&--active
					border-color: $secondary-color
					color: $secondary-color

			.modal__content-group-button[disabled]
				border-color: inherit
				color: inherit
			.modal__content-group-input
				width: 200px
			.modal__content-group-input:disabled
				background-color: rgb(235, 235, 228)

		.modal__content-footer
			border-top: 1px solid #e5e5e5
			justify-content: flex-end
			padding: 8px 0
			background-color: #dcf0f5

.modal--tag
	top: 10%
	width: 530px
	left: calc(50% - 265px)
	z-index: 100
	.modal__content
		.modal__content-body
			overflow: unset
			min-height: 250px
			max-height: 450px
			&--auto
				overflow: auto
		.modal__content-group
			width: 495px
			display: flex
			justify-content: space-between
			align-items: center
			.modal__content-group-item
				.ng-value
					padding-left: 13px !important
				&:nth-child(1)
					display: flex
					align-items: center
					div
						width: 220px
						margin-right: 10px
				&:nth-child(2)
					margin-top: 20px

.modal--report, .modal--email
	width: 650px
	left: calc(50% - 325px)
	z-index: 101
	.modal__content
		border: none
		.modal__content-body
			padding: 0
		.modal__content-footer
			padding: 20px 0

			.modal__content-footer-button
				width: 170px

.modal--email
	width: 450px
	left: calc(50% - 225px)

.fade
	opacity: 0.2
	background: #000
	width: 100%
	height: 100%
	z-index: 101
	top: 0
	left: 0
	position: fixed
	&--extra, &--tag
		z-index: 99
