.form__email
	-webkit-backface-visibility: hidden
	transition: all .6s cubic-bezier(0.645, 0.045, 0.355, 1)
	position: absolute
	margin-left: -512px !important
	left: 50%
	.form__title
		font-size: 18px
		padding: 0
	&--inactive
		transform: rotateX(90deg)

.email-template
	width: 100%
	height: 570px
	overflow: auto
	display: -webkit-box
	display: -ms-flexbox
	display: flex
	padding-bottom: 3em
	&__item
		-webkit-flex-direction: column
		-ms-flex-direction: column
		flex-direction: column
		display: flex
		&:first-child
			width: 27%
			padding-left: 15px
			background-color: white
		&:nth-child(2)
			width: 3%
			background: transparent
		&:last-child
			width: 70%
	&__subject
		padding: 15px
		display: flex
		justify-content: space-between
		background: white
		border-bottom: 1px solid #e5e5e5
		button
			width: inherit
			height: 37px
			i
				margin-left: 5px
	&__input
		width: 87%
		padding: 9px 0
		font-size: 16px
		background: transparent
		border: none
		outline: none
		border-radius: 0
		border-bottom: 1px solid transparent
		transition: .5s all
		&:focus
			border-bottom-color: $secondary-color
		&.ng-invalid
			border-bottom-color: indianred
		&.ng-invalid:focus
			outline: none !important
			-webkit-box-shadow: none !important
			box-shadow: none !important
	&__body
		min-height: 600px
		height: 600px
		textarea
			height: 450px
			font-size: 16px
			padding: 15px
			width: 100%
			outline: none
			resize: none
			border-radius: 0
			border: 1px solid transparent
			transition: .5s all
			&:hover
				border-color: transparent
			&.ng-invalid
				border: 1px solid indianred
			&:hover.ng-invalid
				border-color: indianred


	&__placeholder
		width: 95%
		height: auto
		overflow-y: auto
		min-height: 600px
		max-height: 600px
		&-title
			padding-top: 25px
			&:last-child
				padding-top: 0
		&-item
			width: 100%
			max-width: 215px
			margin: 5px 0
			padding: 5px
			display: flex
			align-items: center
			justify-content: space-between
			border: 1px solid #e5e5e5
			border-radius: 3px
			background: #e5e5e5
			cursor: pointer
			&:last-child
				margin-bottom: 30px
	&__panel
		background-color: #dcf0f5
		padding: 15px
		display: flex
		justify-content: space-between
		.email-template__group
			display: flex
			justify-content: space-between
			width: 170px
			button
				width: 70px
				height: 37px
				padding: 0

.product-label-switch
	.switch-medium
		margin-top: 5px
